import React, { useState, useEffect } from 'react';
import { submitUid, createUid } from './api';
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
const uid = localStorage.getItem('uid');
const pageId = window.location.pathname.slice(
  1,
  window.location.pathname.length
);

const lang = window.navigator.language || navigator.userLanguage;

const german = lang.includes('de');

const disclaimer = german ? `Gastronaut speichert einmalig Datenschutz-Konform Ihre Kontaktdaten.
Auf diese Daten haben weder Mitarbeiter von Gastronaut noch das
Restaurant Zugriff. Bei diesem und weiteren Besuchen wird lediglich
Ihre Individuelle Kennung mit der Kennung des Restaurants und einem
Zeitstempel gespeichert. Sollte ein anderer Gast mit
überschneidenden Besuchen positiv getestet worden sein werden Sie
so gewünscht automatisch benachrichtigt. Sollten Sie Positiv getestet werden
können Sie das Restaurant informieren oder über
https://c.gastronaut.ai den Alarm auslösen.` : `Gastronaut saves your contact details once in compliance with data protection regulations.
Neither Gastronaut employees nor that have access to this data
Restaurant access. During this and other visits, only
Your individual ID with the ID of the restaurant and a
Timestamp saved. Should another guest be with you
Overlapping visits will have tested positive
automatically notified if so requested. You should test positive
you can inform the restaurant or about
https://c.gastronaut.ai trigger the alarm.`

const searchParams = new URLSearchParams(window.location.search);

const params = {
  area: searchParams.get('area'),
  table: searchParams.get('table')
}

const fields = [
  {
    id: 'name',
    label: 'Name',
    name: 'name'
  },
  {
    id: 'email',
    label: 'Email',
    name: 'email'
  },
  {
    id: 'phone',
    label: german ? 'Telefon' : 'Phone',
    name: 'phone'
  },
  {
    id: 'street',
    label: german ? 'Straße' : 'Street',
    name: 'street'
  },
  {
    id: 'zipCode',
    label: german ? 'Postleitzahl' : 'Zip Code',
    name: 'zipCode'
  },
  {
    id: 'city',
    label: german ? 'Stadt' : 'City',
    name: 'city'
  }
];

const isValidEmail = email =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );

const isValidPhoneNumber = phone => /\+?\d{9,}/gm.test(phone.trim());

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0bdbc5',
      contrastText: '#121212'
    }
  }
});

function App() {
  const [state, setstate] = useState({
    name: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    zipCode: '',
    express: false,
    lang
  });
  const [loading, setloading] = useState(true);
  const [success, setsuccess] = useState(false);
  const [errors, seterrors] = useState([]);
  const handleSubmit = e => {
    e.preventDefault();
    let err = {};

    Object.keys(state).forEach(key => {
      if (!state[key]) {
        err[key] = german ? 'Pflichtfeld' : 'Mandatory';
      }
    });

    if (Object.keys(err).length) {
      seterrors(err);
      return;
    }

    setloading(true);

    createUid(state, pageId, params).then(({ uid, redirectUrl, action }) => {
      localStorage.setItem('uid', uid);

      setloading(false);

      if(redirectUrl)  {
        window.location.replace(redirectUrl);
      } else if(action) {
        console.log(action)
      } else {
        setsuccess(true);
      }

    });
  };

  useEffect(() => {
    if (uid && pageId && pageId !== 'error') {
      submitUid(uid, pageId, params).then(({ redirectUrl, message }) => {
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else if (message) {
          localStorage.setItem('uid', '');
          setloading(false);
        } else {
          setloading(false);
          setsuccess(true);
        }

      });
    } else if (uid && !pageId) {
      window.location.replace('error');
    } else {
      setloading(false);
    }
  }, []);

  const handleChange = ({ target: { name, value } }) =>
    setstate(st => ({ ...st, [name]: value }));

  const handleBlur = ({ target: { name, value } }) => {
    let error = null;

    if (!value) {
      error = german ? 'Pflichtfeld' : 'Mandatory';
    } else if (name === 'email' && !isValidEmail(value)) {
      error = german ? 'Keine valide Email' : 'No valid Email';
    } else if(name === 'phone'&& !isValidPhoneNumber(value)) {
      error = german ? 'Keine valide Telefon Nummer' : 'No valid phone number';
    }

    seterrors(err => ({ ...err, [name]: error }));
  };

  if (pageId === 'error') {
    return (
      <div className="loadingContainer">
        <h3>Es ist ein Fehler aufgetreten</h3>
      </div>
    );
  } else if (!pageId) {
    return (
      <div className="loadingContainer">
        <h3>Willkommen bei Gastronaut</h3>
      </div>
    );
  } else if (loading) {
    return (
      <div className="loadingContainer">
        <CircularProgress />
      </div>
    );
  } else if (success) {
    return (
      <div className="loadingContainer">
        <img src="/success.gif" width="100px" alt="success" style={{ marginBottom: 5 }} />
        <h4>Sie wurden Erfolgreich registriert</h4>
      </div>
    ) 
  } else {
    return (
      <ThemeProvider theme={theme}>
        <form className="App" onSubmit={handleSubmit} style={{ padding: 20 }}>
          <Typography variant="h5">Covid-19 Formular</Typography>
          {fields.map(({ id, ...field }) => (
            <TextField
              {...field}
              autoComplete={field.name}
              onChange={handleChange}
              error={!!errors[id]}
              helperText={errors[id] || " "}
              key={id}
              onBlur={handleBlur}
              fullWidth
            />
          ))}
          <FormControlLabel
            control={<Checkbox checked={state.express} style={{ top: -9 }} onChange={() => setstate(st => ({ ...st, express: !st.express }))} name="express" />}
            label={german ? "Ich möchte bei Kontakt direkt per Email/SMS benachrichtigt werden" : "I would like to be contacted via Email if I have a covid contact"}
            style={{ marginTop: 10 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: '10px 0' }}
            disableElevation
          >
            {german ? 'Abschicken' : 'Submit'}
          </Button>
          <small>
            {disclaimer}
          </small>
        </form>
      </ThemeProvider>
    );
  }
}

export default App;
