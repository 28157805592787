export const createUid = async (data, pageId, area = {}) => {
  return fetch('https://api.gastronaut.ai/createUid', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data, pageId, ...area })
  }).then(response => response.json());
};

export const submitUid = async (uid, pageId, area = {}) => {
  return fetch('https://api.gastronaut.ai/submitUid', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ uid, pageId, ...area })
  }).then(response => response.json());
};
